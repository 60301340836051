import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  return (
    <LogoContainer>
      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M44.3952 31.2C53.064 31.2 61.7328 31.2 70.4016 31.2C71.2992 31.2 71.5152 31.4256 71.4816 32.2992C71.4144 34.2672 71.4192 36.24 71.4816 38.208C71.5104 39.0288 71.2128 39.168 70.4736 39.168C67.5216 39.1344 64.5648 39.168 61.608 39.1344C60.7776 39.1344 60.5616 39.3648 60.5664 40.1856C60.5952 47.9904 60.5664 55.7904 60.6144 63.5904C60.6144 64.7808 60.2736 65.0304 59.1744 64.9728C57.048 64.8816 54.9168 64.9056 52.7904 64.9728C51.96 64.9728 51.7488 64.752 51.7536 63.9264C51.7824 56.088 51.7536 48.2448 51.7968 40.4064C51.7968 39.36 51.552 39.0864 50.5152 39.1344C48.4704 39.2208 46.4208 39.1968 44.3712 39.1344C43.4496 39.1056 43.2 39.2304 43.4448 40.2768C44.7443 45.6991 44.6799 51.359 43.2576 56.7504C41.9088 61.8672 38.5104 64.6224 33.216 64.8C28.1808 64.968 23.136 64.848 18.0912 64.9248C17.0736 64.9248 17.2704 64.3632 17.2704 63.7968V37.92C17.2704 36.0672 17.3184 34.2144 17.2704 32.3664C17.2368 31.4544 17.472 31.2 18.4128 31.2048C27.0816 31.2432 35.7504 31.2048 44.4192 31.2048L44.3952 31.2ZM26.0832 47.9664C26.0832 50.64 26.0832 53.3184 26.0832 55.992C26.0832 56.7072 26.1792 57.0576 27.0432 57.0048C28.296 56.928 29.5584 57.0048 30.8208 56.9808C33.3504 56.9472 34.56 55.9632 35.0496 53.4528C35.7505 49.8062 35.7505 46.0594 35.0496 42.4128C34.6032 40.0128 33.6096 39.2352 31.1712 39.1392C29.7984 39.0912 28.416 39.168 27.0432 39.1104C26.3136 39.0816 26.0832 39.3072 26.1168 40.0368C26.112 42.6912 26.088 45.3264 26.0832 47.9664Z"
            fill={fill}
          />
          <path
            d="M71.5872 60.5616C71.688 59.5392 71.0496 57.8736 71.832 57.2016C72.5136 56.5872 74.1504 57.024 75.3648 57.0096C76.5024 57.0096 77.6448 57.0096 78.7872 57.0096C79.2912 57.0096 79.464 57.1392 79.4592 57.6672C79.4304 59.8304 79.4304 61.9952 79.4592 64.1616C79.4592 64.7088 79.3392 64.9152 78.7488 64.9056C76.584 64.872 74.4288 64.9056 72.2544 64.9056C71.7456 64.9056 71.5632 64.7664 71.5776 64.2432C71.6112 63.0576 71.5872 61.92 71.5872 60.5616Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="96" height="96" fill={fill} />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <path
          d="M44.3952 31.2C53.064 31.2 61.7328 31.2 70.4016 31.2C71.2992 31.2 71.5152 31.4256 71.4816 32.2992C71.4144 34.2672 71.4192 36.24 71.4816 38.208C71.5104 39.0288 71.2128 39.168 70.4736 39.168C67.5216 39.1344 64.5648 39.168 61.608 39.1344C60.7776 39.1344 60.5616 39.3648 60.5664 40.1856C60.5952 47.9904 60.5664 55.7904 60.6144 63.5904C60.6144 64.7808 60.2736 65.0304 59.1744 64.9728C57.048 64.8816 54.9168 64.9056 52.7904 64.9728C51.96 64.9728 51.7488 64.752 51.7536 63.9264C51.7824 56.088 51.7536 48.2448 51.7968 40.4064C51.7968 39.36 51.552 39.0864 50.5152 39.1344C48.4704 39.2208 46.4208 39.1968 44.3712 39.1344C43.4496 39.1056 43.2 39.2304 43.4448 40.2768C44.7443 45.6991 44.6799 51.359 43.2576 56.7504C41.9088 61.8672 38.5104 64.6224 33.216 64.8C28.1808 64.968 23.136 64.848 18.0912 64.9248C17.0736 64.9248 17.2704 64.3632 17.2704 63.7968V37.92C17.2704 36.0672 17.3184 34.2144 17.2704 32.3664C17.2368 31.4544 17.472 31.2 18.4128 31.2048C27.0816 31.2432 35.7504 31.2048 44.4192 31.2048L44.3952 31.2ZM26.0832 47.9664C26.0832 50.64 26.0832 53.3184 26.0832 55.992C26.0832 56.7072 26.1792 57.0576 27.0432 57.0048C28.296 56.928 29.5584 57.0048 30.8208 56.9808C33.3504 56.9472 34.56 55.9632 35.0496 53.4528C35.7505 49.8062 35.7505 46.0594 35.0496 42.4128C34.6032 40.0128 33.6096 39.2352 31.1712 39.1392C29.7984 39.0912 28.416 39.168 27.0432 39.1104C26.3136 39.0816 26.0832 39.3072 26.1168 40.0368C26.112 42.6912 26.088 45.3264 26.0832 47.9664Z"
          fill={fill}
        />
        <path
          d="M71.5872 60.5616C71.688 59.5392 71.0496 57.8736 71.832 57.2016C72.5136 56.5872 74.1504 57.024 75.3648 57.0096C76.5024 57.0096 77.6448 57.0096 78.7872 57.0096C79.2912 57.0096 79.464 57.1392 79.4592 57.6672C79.4304 59.8304 79.4304 61.9952 79.4592 64.1616C79.4592 64.7088 79.3392 64.9152 78.7488 64.9056C76.584 64.872 74.4288 64.9056 72.2544 64.9056C71.7456 64.9056 71.5632 64.7664 71.5776 64.2432C71.6112 63.0576 71.5872 61.92 71.5872 60.5616Z"
          fill={fill}
        />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
